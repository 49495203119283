<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Boton Agregar nuevo Cobro Online -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Cobro Online"
              @click="nuevoCobroOnline()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <!-- Empresa, Sucursal, Pto Vta y Operacion -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresa"
                      item-text="nombre_corto"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      return-object
                      @change="setLocales()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursal"
                      item-text="nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      return-object
                      @change="setLocales()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Punto de Venta
                    <v-autocomplete
                      v-model="selectedPtoVta"
                      item-text="nombre"
                      :items="ptosVta"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Operación
                    <v-autocomplete
                      v-model="selectedOperacion"
                      item-text="operacion_nombre"
                      :items="operaciones"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Estado de Cobro, Fecha y Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="cobros"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom v-if="((item.estado_id == 2 || item.estado_id == 8) && tienePermiso == 1)">
              <template v-slot:activator="{ on }">
                <BtnConfirmar
                  :icon_button="true"
                  :small="true"
                  icono="fas fa-ban"
                  @action="anularCobro(item)"
                  color="error"
                  title="Anular Cobro"
                  :texto="`¿Esta seguro de Anular el Cobro ${item.pedido_numero}?`"
                  v-on="on"
                />
              </template>
              <span>Anular Cobro</span>
            </v-tooltip>
            <v-tooltip bottom v-if="(item.estado_id == 2 || item.estado_id == 4 || item.estado_id == 8 || item.estado_id == 9)">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="descargarCompCobro(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-print</v-icon>
                </v-btn>
              </template>
              <span>Descargar Comprobante</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado == 'ERROR' || item.estado == 'PENDIENTE'">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="reprocesarCobro(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-sync</v-icon>
                </v-btn>
              </template>
              <span>Reprocesar Cobro</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Visualizador PDF -->
    <PDFViewer 
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="nombreVer"
      :titulo="nombreVer"
    />
  </div>
</template>

<script>
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import moment from 'moment'
import { format_money, roundNumber } from '../../util/utils'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'
import router from '../../router'
import { pdfCobroOnline } from '../../util/plantillas/pdfs'
export default {
  data(){
    return {
      panel: 0,
      empresas: [],
      selectedEmpresa: {},
      sucursales: [],
      selectedSucursal: {},
      ptosVta: [],
      ptosVtaAux: [],
      selectedPtoVta: {},
      operaciones: [],
      operacionesAux: [],
      selectedOperacion: {},
      limpiarFecha: false,
      fechaRango: [null, null],
      estados: [],
      selectedEstado: {},
      load: false,
      cobros: [],
      headers: [
        { text: 'Pedido', align: 'center', value: 'pedido_numero' },
        { text: 'Fecha Cupón', align: 'center', value: 'fecha_cupon' },
        { text: 'Transacción', align: 'center', value: 'transaccion_id' },
        { text: 'Punto de Venta', align: 'center', value: 'pto_vta' },
        { text: 'Titular', align: 'center', value: 'titular' },
        { text: 'DNI', align: 'center', value: 'documento_numero' },
        { text: 'Estado', align: 'center', value: 'estado' },
        { text: 'Importe', align: 'center', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      search: '',
      dialogPdf: false,
      pdf: '',
      nombreVer: '',
      tienePermiso: 0
    }
  },
  components: {
    FechaPickerRango,
    BtnFiltro,
    SearchDataTable,
    BtnConfirmar,
    PDFViewer
  },
  created () {
    this.initForm()
  },
  methods: {
    addTotalVenta(){
      this.fp.monto_cobrar = roundNumber(this.fp.monto_cobrar, 2) + 10000
    },
    subtractTotalVenta(){
      this.fp.monto_cobrar = roundNumber(this.fp.monto_cobrar, 2) - 5000
    },
    setFP(value){
      console.log('emitido', value)
    },
    async initForm(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.ptosVtaAux = JSON.parse(localStorage.getItem('locales'))
      // obtengo las operaciones
      let operacionesPeticion = await this.$store.dispatch('cobros/init_index_cobros', {nuevo: 1, id: 0, tipo_pedido: 0})
      this.$store.state.loading = false
      if (operacionesPeticion.resultado == 1){
        this.operacionesAux = operacionesPeticion.operaciones
        this.estados = operacionesPeticion.estados
        this.tienePermiso = operacionesPeticion.permiso
      }else{
        this.$store.dispatch('show_snackbar', {
          text: operacionesPeticion.msj,
          color: 'error',
        })
      }
    },
    validarObligatorios(){
      if (Object.keys(this.selectedEmpresa).length == 0){
        return {valido: false, msj: 'Debe seleccionar la Empresa'}
      }
      return {valido: true, msj: 'OK'}
    },
    async buscar(){
      this.cobros = []
      this.search = ''
      this.load = true
      let correcto = this.validarObligatorios()
      if (correcto.valido == false){
        this.load = false
        this.$store.dispatch('show_snackbar', {
          text: correcto.msj,
          color: 'warning',
        })
        return
      }
      // filtros
      let local = 0
      if (Object.keys(this.selectedPtoVta).length > 0){
        local = this.selectedPtoVta.id
      }
      let sucursal = 0
      if (Object.keys(this.selectedSucursal).length > 0){
        sucursal = this.selectedSucursal.id
      }
      let op = 0
      if (Object.keys(this.selectedOperacion).length > 0){
        op = this.selectedOperacion.operacion_id
      }
      let estado = 0
      if (Object.keys(this.selectedEstado).length > 0){
        estado = this.selectedEstado.estado_id
      }
      let fechaDesde = moment(this.fechaRango[0]).format("DD/MM/YYYY")
      let fechaHasta = ''
      if (this.fechaRango[1] != null && this.fechaRango[1] != undefined && this.fechaRango[1].toString().length > 0){
        fechaHasta = moment(this.fechaRango[1]).format("DD/MM/YYYY")
      }
      let cobrosPeticion = await this.$store.dispatch('cobros/get_cobros_online', {
        empresa: this.selectedEmpresa.id,
        sucursal: sucursal,
        pto_vta: local,
        operacion: op,
        estado: estado,
        fecha_desde: fechaDesde,
        fecha_hasta: fechaHasta
      })
      this.load = false
      if (cobrosPeticion.resultado == 1){
        this.cobros = cobrosPeticion.cobros
      }else{
        this.$store.dispatch('show_snackbar', {
          text: cobrosPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.selectedPtoVta = {}
      this.selectedOperacion = {}
      this.selectedEstado = {}
      this.limpiarFecha = true
    },
    setLocales(){
      this.ptosVta = []
      this.operaciones = []
      // locales
      if (Object.keys(this.selectedEmpresa).length > 0 && Object.keys(this.selectedSucursal).length > 0){
        this.ptosVta = this.ptosVtaAux.filter(element => element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }
      // operaciones
      if (Object.keys(this.selectedEmpresa).length > 0){
        this.operaciones = this.operacionesAux.filter(element => element.empresa_id == this.selectedEmpresa.id)
      }
    },
    async anularCobro(item){
      // es de decidir?
      if (item.pasarela_pago != 2){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede anular un Cobro que no se haya echo por DECIDIR por este medio.',
          color: 'info',
        })
        return
      }
      // esta sin asociar?
      if (item.estado_id == 8){
        // primero voy a ver si puedo anularlo
        this.checkCobroSinAsociar(item)
        return
      }
      this.load = true
      let body = {
        transaccion_id: item.transaccion_id,
        empresa_id: item.empresa_codigo,
        importe: '0'
      }
      let anulacionPeticion = await this.$store.dispatch('cobros/anular_cobro_electronico', body)
      this.load = false
      if (anulacionPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El Cobro se anuló correctamente.',
          color: 'success',
        })
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: anulacionPeticion.msj,
          color: 'error',
        })
      }
    },
    async checkCobroSinAsociar(item){
      this.load = true
      let checkPeticion = await this.$store.dispatch('cobros/check_cobro_sin_asociar', {transaccion_id: item.transaccion_id})
      if (checkPeticion.resultado == 1){
        // prosigo con la anulación
        let body = {
          transaccion_id: item.transaccion_id,
          empresa_id: item.empresa_codigo,
          importe: '0'
        }
        let anulacionPeticion = await this.$store.dispatch('cobros/anular_cobro_electronico', body)
        this.load = false
        if (anulacionPeticion.resultado == 1){
          this.$store.dispatch('show_snackbar', {
            text: 'El Cobro se anuló correctamente.',
            color: 'success',
          })
          setTimeout(() => [this.buscar()], 2000)
        }else{
          this.$store.dispatch('show_snackbar', {
            text: anulacionPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.load = false
        this.$store.dispatch('show_snackbar', {
          text: checkPeticion.msj,
          color: 'error',
        })
      }
    },
    async descargarCompCobro(item){
      this.$store.state.loading = true
      let cobroPeticion = await this.$store.dispatch('cobros/get_pdf_cobro_data', {id: item.id})
      this.$store.state.loading = false
      if (cobroPeticion.resultado == 1){
        // pido el pdf
        let pdfPeticion = await pdfCobroOnline(cobroPeticion.cobro)
        if (pdfPeticion.resultado == 1){
          this.pdf = pdfPeticion.pdf
          this.nombreVer = 'Comprobante del Cobro N°' + item.pedido_numero
          this.dialogPdf = true
        }else{
          this.$store.dispatch('show_snackbar', {
            text: pdfPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: cobroPeticion.msj,
          color: 'error',
        })
      }
    },
    nuevoCobroOnline(){
      router.push({path: '/nuevo-cobro'})
    },
    reprocesarCobro(item){
      let path = '/reprocesar-cobro/' + item.tipo_pedido + '/' + item.pedido_numero
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  },
}
</script>