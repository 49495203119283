<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card
      flat
    >
      <!-- Titulo -->
      <v-card-title
        class="pt-0"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
      >
        Financiaciones Disponibles
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <br/>
      <!-- Listado de Financiaciones -->
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="financiaciones"
              :loading="load"
              dense
              :search="search"
            >
              <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Sin Datos para mostrar -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay financiaciones disponibles
                </v-alert>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon color="success" @click="seleccionarFP(item)" v-on="on">
                      <v-icon class="mr-2" small>fas fa-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar Financiación</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import {format_money} from '../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalFps', value)
      }
    },
    financiaciones: {
      get(){
        return this.datos.financiaciones
      }
    }
  },
  components: {
    SearchDataTable,
  },
  data() {
    return {
      headers: [
        { text: 'Descripcion', align: 'center', value: 'descripcion' },
        { text: 'Tarjeta Importe', align: 'center', value: 'tarjeta_importe', formatter: format_money },
        { text: 'Recargo', align: 'center', value: 'ajuste_x_fp', formatter: format_money },
        { text: 'Importe x Cuotas', align: 'center', value: 'importe_cuotas', formatter: format_money },
        { text: 'Importe Final', align: 'center', value: 'total_final', formatter: format_money },
        { text: 'Seleccionar', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: ''
    }
  },
  methods: {
    seleccionarFP(item){
      this.$emit('setFP', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.search = ''
      }
    }
  },
}
</script>